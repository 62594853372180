const { default: axios } = require('axios');
const { get } = require('lodash');

require('./bootstrap');

// contact form logic
function posValidate(form, field){

    var fieldValue = document.forms[form][field].value;

    var positive = false;

    if(field === "email" && fieldValue !== ""){
        if(fieldValue.includes('@') && fieldValue.includes('.')){
            positive = true;

        } else {
            positive = false;
        }
    }

    if(field === "name" && fieldValue !== ""){

        // positive is true or false
        if(fieldValue.length > 1 && fieldValue.length <= 50){
            positive = true
        }
        else{
            positive = false
        }
    }

    if(field === "motivation" && fieldValue !== ""){

        // positive is true or false
        if(fieldValue.length > 5 && fieldValue.length <= 1000){
            positive = true
        }
        else{
            positive = false
        }
    }

    var errorElem = document.querySelector(`.err-${field}`);

    // check positive
    if(positive){

        if(errorElem) errorElem.innerHTML = "";
        document.querySelector(`#ok-${field}`).style.cssText = "display: inline-block; color: green;";

        // remove backend validation errors
        document.querySelector(`.input-${field}`).classList.remove('error');

    } else {

        document.querySelector(`#ok-${field}`).style.cssText = "display: none;";

    }
}

// find form inputs
var inputFields = document.querySelectorAll(".form-input");

inputFields.forEach((f) => {

    f.addEventListener('input', function(){
        posValidate("solliciteer", f.getAttribute('name'))
    });

});

let disabled = false

document.getElementById("form").addEventListener("submit", submit)

function submit(e) {
    {
        e.preventDefault()
        
        if (disabled) {
            return
        }

        showSpinner()
    
        disabled = true
    
        const formdata = new FormData(e.target)
        
        axios.post(e.target.action, formdata).then(response => {

            hideSpinner()

            let errors = response.data.errors
            
            const inputs = document.querySelectorAll('input')
            inputs.forEach(i => {
                i.classList.remove('error')
            })
            const textarea = document.querySelector('textarea')
            textarea.classList.remove('error')
    
    
            if(response.data.success){
                // hide form
                document.getElementById('form').style.display = "none"
                
                // show success
                document.getElementById('success').style.cssText = "display:block;"


    
            } else {
    
                // loop over errors and display them in reserved element
                for( const key in errors){
                    document.querySelector(`.err-${key}`).innerText = errors[key];
                    
                    const input = document.querySelector(`.input-${key}`)
                    if (input) input.classList.add('error')
                }
                disabled = false
            }
    
        }).catch(e => {
            console.log('Oops!, er is iets misgegaan met het verzenden van het formuler: ', e);
        })
    
    }
}

function showSpinner() {
    document.querySelector(".spinners").style.display = "inline-block";
    document.querySelector(".is-loading").style.display = "inline-block";
    document.querySelector(".not-loading").style.display = "none";
}

function hideSpinner() {
    document.querySelector(".spinners").style.display = "none";
    document.querySelector(".is-loading").style.display = "none";
    document.querySelector(".not-loading").style.display = "inline-block";
}


setupModal = () => {
    const modalTrigger = document.querySelector(".privacy-modal-trigger")
    const modal = document.querySelector(".overlay")

    if (modal && modalTrigger) {
        const modalWindow = document.querySelector(".overlay-window")
        const modalWindowClose = document.querySelector(".overlay-window-close")

        modalWindow.addEventListener('click', e => {
            e.stopPropagation()
        })

        modalWindowClose.addEventListener('click', e => {
            modal.style.display = 'none'
            document.body.classList.remove('has-overlay')
        })

        modal.addEventListener('click', e => {
            modal.style.display = 'none'
            document.body.classList.remove('has-overlay')
        })

        modalTrigger.addEventListener('click', e => {
            e.preventDefault()
            modal.style.display = 'block'
            document.body.classList.add('has-overlay')
        })
    }
}

setupModal()

const fileInputText = document.querySelector('.upload span')
const fileInput = document.querySelector('.upload input[type="file"]')
const uploadConfirmation = document.querySelector('.upload-confirmation');
fileInput.addEventListener('change', e => {
    fileInputText.style.display = "inline-block"
    uploadConfirmation.innerText = "Bestand: " + e.target.files[0].name;
})
